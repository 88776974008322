import { CameraSource } from "@capacitor/camera";
import { useModalState } from "@clipboard-health/ui-react";
import { isDefined } from "@clipboard-health/util-ts";
import { isCapacitorPlatform, useToast } from "@src/appV2/lib";
import { APP_V2_USER_EVENTS, logEvent } from "@src/appV2/lib/analytics";
import { useDefinedWorker } from "@src/appV2/Worker/useDefinedWorker";
import constate from "constate";
import { useRef, useState } from "react";

import { DocumentUIVariant } from "../../Documents/constants";
import { type HcpRequirement } from "../../Documents/types";
import { CameraPermissionDeniedError } from "../errors/CameraPermissionDeniedError";
import { type AllowedCameraSourceType, type UploadFileData } from "../types";
import {
  getFileSelectionTypeFromSelectedFiles,
  validateAndGetUploadFile,
  validateNewSelectedFile,
} from "../utils";
import { useBrowserOnFileChange } from "./useBrowserOnFileChange";
import { useMobileOnFileSelect } from "./useMobileOnFileSelect";
import { useTakePhoto } from "./useTakePhoto";
import { useUploadAndCreateDocument } from "./useUploadAndCreateDocument";

export const IGNORED_FILE_UPLOAD_ERROR_MESSAGES = {
  IMAGE_NOT_SELECTED: "No image picked",
  CAMERA_PHOTO_NOT_TAKEN: "User cancelled photos app",
};

export interface UploadFileProps {
  requirementId?: string;
  personalIdSubtype?: string;
  selectedRequirement?: HcpRequirement;
}

/*
  We can either upload a single PDF file or a single Image file.
  We allow multiple image inputs, but we combine into a single pdf before uploading
*/
function useDocumentUpload() {
  const { showErrorToast } = useToast();
  const worker = useDefinedWorker();

  const [selectedFiles, setSelectedFiles] = useState<UploadFileData[]>([]);
  const permissionDeniedModalState = useModalState();
  const { openModal } = permissionDeniedModalState;
  const [permissionDeniedSource, setPermissionDeniedSource] = useState<
    AllowedCameraSourceType | undefined
  >(undefined);

  const inputFileRef = useRef<HTMLInputElement>(null);

  const { uploadAndCreateDocument, isLoading } = useUploadAndCreateDocument();

  const clearSelectedFiles = () => {
    setSelectedFiles([]);
  };

  const removeSelectedFile = async (indexToRemove: number) => {
    setSelectedFiles((selectedFiles) => {
      return selectedFiles.filter((_, index) => index !== indexToRemove);
    });
  };

  const appendToSelectedFiles = (newSelectedFile: UploadFileData) => {
    validateNewSelectedFile({ newSelectedFile, selectedFiles });
    setSelectedFiles((selectedFiles) => [...selectedFiles, newSelectedFile]);
  };

  const uploadAndClearSelectedFiles = async (uploadFileProps: UploadFileProps) => {
    try {
      const { selectedRequirement, personalIdSubtype, requirementId } = uploadFileProps;

      if (!isDefined(requirementId) || !isDefined(selectedRequirement)) {
        return;
      }

      const file = await validateAndGetUploadFile(selectedFiles);

      await uploadAndCreateDocument({
        hcpId: worker.userId,
        file,
        requirementId,
        selectedRequirement,
        personalIdSubtype,
      });

      logEvent(APP_V2_USER_EVENTS.UPLOAD_DOCUMENT_SUCCESS, {
        ui_variant: DocumentUIVariant.V2,
      });
      clearSelectedFiles();
    } catch {
      logEvent(APP_V2_USER_EVENTS.UPLOAD_DOCUMENT_FAILURE, {
        ui_variant: DocumentUIVariant.V2,
      });
      showErrorToast("Could not upload file!");
    }
  };

  const browserOnFileChange = useBrowserOnFileChange({
    onSuccess: appendToSelectedFiles,
    onError: () => {
      showErrorToast("Could not add selected file");
    },
  });

  const browserSelectFile = () => {
    inputFileRef?.current?.click();
  };

  const mobileSelectFile = useMobileOnFileSelect({
    onSuccess: appendToSelectedFiles,
    onError: () => {
      showErrorToast("Could not add selected file");
    },
  });

  const takePhotoOnError = (error: Error | CameraPermissionDeniedError) => {
    if (error instanceof CameraPermissionDeniedError) {
      setPermissionDeniedSource(error.getCameraSource());
      openModal();
    } else {
      if (Object.values(IGNORED_FILE_UPLOAD_ERROR_MESSAGES).includes(error?.message)) {
        return;
      }

      showErrorToast("Could not add photo");
    }
  };

  const takeCameraPhoto = useTakePhoto({
    source: CameraSource.Camera,
    onSuccess: appendToSelectedFiles,
    onError: takePhotoOnError,
  });

  const selectGalleryPhoto = useTakePhoto({
    source: CameraSource.Photos,
    onSuccess: appendToSelectedFiles,
    onError: takePhotoOnError,
  });

  return {
    selectedFiles,
    fileSelectionType: getFileSelectionTypeFromSelectedFiles(selectedFiles),
    clearSelectedFiles,
    removeSelectedFile,
    permissionDeniedModalState,
    permissionDeniedSource,
    uploadAndClearSelectedFiles,
    isUploading: isLoading,
    inputFileRef,
    browserOnFileChange,
    takeCameraPhoto: isCapacitorPlatform() ? takeCameraPhoto : browserSelectFile,
    selectGalleryPhoto: isCapacitorPlatform() ? selectGalleryPhoto : browserSelectFile,
    selectFile: isCapacitorPlatform() ? mobileSelectFile : browserSelectFile,
  };
}

export const [DocumentUploadProvider, useDocumentUploadContext] = constate(useDocumentUpload);
