import { type UseModalState } from "@clipboard-health/ui-react";
import { isDefined, isNullOrUndefined } from "@clipboard-health/util-ts";
import { Resources } from "@src/appV2/Accounts/Documents/resources/constants";
import { RequirementWorkflowType } from "@src/appV2/Accounts/Documents/resources/requirements/constants";
import { type HcpRequirement } from "@src/appV2/Accounts/Documents/types";
import { helloSignClient } from "@src/appV2/HelloSign/helloSignClient";
import { APP_V2_APP_EVENTS, APP_V2_USER_EVENTS, useToast } from "@src/appV2/lib";
import { logEvent } from "@src/appV2/lib/analytics";
import { useDefinedWorker } from "@src/appV2/Worker/useDefinedWorker";
import { isAxiosError } from "axios";
import { type Dispatch, type SetStateAction, useRef, useState } from "react";

import { HelloSignWorkflowSessionStatus } from "../../../Documents/resources/hellosign/constants";
import {
  HELLO_SIGN_SESSION_RESOURCE,
  useCreateHelloSignSession,
  useUpdateHelloSignSession,
} from "../../api/hellosign";
import { ErrorMessageVariants } from "../../components/documentUploadModals/constants";
import {
  type SetDocumentCompletedModalProps,
  type SetDocumentUploadErrorModalProps,
} from "../useDocumentLoadingModals";
import { type RefetchDocumentPropertiesProps } from "../useDocumentProperties";
import { useGetHelloSignRequirement } from "../useGetHelloSignRequirement";
import {
  useHelloSignClientEvents,
  useHelloSignHandleSuccessOrError,
  useHelloSignModals,
  useHelloSignPolling,
} from "./internalHooks";

interface UseHelloSignEmbeddedFlowProps {
  selectedRequirement?: HcpRequirement;
  refetchDocumentProperties: (props: RefetchDocumentPropertiesProps) => Promise<void>;
  documentUploadingModalState: UseModalState;
  documentCompletedModalState: UseModalState;
  documentErroredModalState: UseModalState;
  setDocumentCompletedModalProps: Dispatch<SetStateAction<SetDocumentCompletedModalProps>>;
  setDocumentUploadErrorModalProps: Dispatch<SetStateAction<SetDocumentUploadErrorModalProps>>;
  documentsDropOffFlowId: string;
  documentDetailsPageContentRef: React.RefObject<HTMLDivElement>;
  setHelloSignClientIsOpen: Dispatch<SetStateAction<boolean>>;
}

export function useHelloSignEmbeddedFlow(props: UseHelloSignEmbeddedFlowProps) {
  const {
    selectedRequirement,
    documentCompletedModalState,
    setDocumentCompletedModalProps,
    documentErroredModalState,
    setDocumentUploadErrorModalProps,
    refetchDocumentProperties,
    documentDetailsPageContentRef,
    documentsDropOffFlowId,
    setHelloSignClientIsOpen,
  } = props;
  const { showErrorToast } = useToast();
  const [helloSignSessionId, setHelloSignSessionId] = useState<string | undefined>(undefined);
  const { reqId: requirementId } = selectedRequirement ?? {};
  const helloSignOpenStartTime = useRef<number | undefined>(undefined);

  const worker = useDefinedWorker();
  const { mutateAsync: createHelloSignSession, isLoading: isCreatingHelloSignSession } =
    useCreateHelloSignSession({
      requirementId,
      workerId: worker.userId,
    });
  const {
    mutateAsync: updateHelloSignSessionToSigned,
    isLoading: isUpdatingHelloSignSessionToSigned,
  } = useUpdateHelloSignSession({
    helloSignSessionId,
    requirementId,
    workerId: worker.userId,
    include: Resources.HCP_DOCUMENTS,
  });
  const { mutateAsync: updateHelloSignSessionToErrored } = useUpdateHelloSignSession({
    helloSignSessionId,
    requirementId,
    workerId: worker.userId,
  });

  const { openErrorModal, openSuccessModal } = useHelloSignModals({
    setDocumentCompletedModalProps,
    setDocumentUploadErrorModalProps,
    documentErroredModalState,
    documentCompletedModalState,
  });

  const { isPollingHelloSignSession, setIsPollingHelloSignSession } = useHelloSignPolling({
    requirementId,
    helloSignSessionId,
    openErrorModal,
    openSuccessModal,
    refetchDocumentProperties,
  });

  const { findHelloSignRequirementInChildRequirements } = useGetHelloSignRequirement({
    selectedRequirement,
  });
  const isCompositeRequirement =
    selectedRequirement?.requirementWorkflowType === RequirementWorkflowType.COMPOSITE;

  const childRequirementId = findHelloSignRequirementInChildRequirements()?._id;

  async function startHelloSignEmbeddedFlow() {
    try {
      if (isNullOrUndefined(requirementId)) {
        throw new Error("Missing requirementId");
      }

      const session = await createHelloSignSession({
        data: {
          type: HELLO_SIGN_SESSION_RESOURCE,
          ...(isCompositeRequirement &&
            isDefined(childRequirementId) && {
              attributes: {
                childRequirementId,
              },
            }),
        },
      });

      setHelloSignSessionId(session.data.id);

      if (isNullOrUndefined(session.data.attributes.signUrl)) {
        throw new Error("Missing signUrl");
      }

      const { signUrl } = session.data.attributes;
      logEvent(APP_V2_APP_EVENTS.HELLO_SIGN_SESSION_STARTED, {
        requirementId,
        signUrl,
        helloSignSessionId: session.data.id,
      });
      helloSignOpenStartTime.current = Date.now();
      setHelloSignClientIsOpen(true);
      helloSignClient.open(signUrl, {
        skipDomainVerification: true,
        container: isDefined(documentDetailsPageContentRef.current)
          ? documentDetailsPageContentRef.current
          : undefined,
      });
    } catch (error) {
      if (isAxiosError(error) && error.response?.status === 409) {
        openErrorModal(ErrorMessageVariants.ALREADY_SIGNED, false);
      } else {
        showErrorToast("Something went wrong, please try again later.");
      }
    }
  }

  function fallbackToPolling() {
    logEvent(APP_V2_APP_EVENTS.HELLO_SIGN_SESSION_FALLBACK_TO_POLLING, {
      requirementId,
      helloSignSessionId,
    });
    setIsPollingHelloSignSession(true);
  }

  const { handleHelloSignSuccess } = useHelloSignHandleSuccessOrError({
    refetchDocumentProperties,
    openSuccessModal,
    openErrorModal,
    requirementId,
    helloSignSessionId,
    onUnfinishedState: fallbackToPolling,
  });

  useHelloSignClientEvents({
    onSign: async () => {
      setHelloSignClientIsOpen(false);
      logEvent(APP_V2_USER_EVENTS.DOCUMENTS_UPLOAD_FLOW_DOCUMENT_DETAILS_SUBMISSION_FINISHED, {
        requirementId,
        requirementWorkflowType: selectedRequirement?.requirementWorkflowType,
        documentsDropOffFlowId,
      });
      try {
        const helloSignSession = await updateHelloSignSessionToSigned({
          status: HelloSignWorkflowSessionStatus.SIGNED,
        });
        handleHelloSignSuccess(helloSignSession);
      } catch {
        fallbackToPolling();
      }
    },
    onError: async (errorCode) => {
      setHelloSignClientIsOpen(false);
      openErrorModal();
      await updateHelloSignSessionToErrored({
        status: HelloSignWorkflowSessionStatus.ERRORED,
        errorMessage: errorCode,
      });
    },
    onCancel: () => {
      setHelloSignClientIsOpen(false);
    },
    requirementId,
    helloSignSessionId,
    helloSignOpenStartTime: helloSignOpenStartTime.current,
  });

  return {
    isCreatingHelloSignSession,
    isLoadingHelloSignDocument: isPollingHelloSignSession || isUpdatingHelloSignSessionToSigned,
    startHelloSignEmbeddedFlow,
  };
}
