import { isNullOrUndefined } from "@clipboard-health/util-ts";
import { HelloSignWorkflowSessionStatus } from "@src/appV2/Accounts/Documents/resources/hellosign/constants";
import { type HelloSignSessionResponse } from "@src/appV2/Accounts/Documents/resources/hellosign/types";
import { DocumentRequirementType, DocumentStatus } from "@src/appV2/Accounts/Documents/types";
import { APP_V2_APP_EVENTS } from "@src/appV2/lib";
import { logEvent } from "@src/appV2/lib/analytics";
import { useCallback } from "react";
import { useHistory } from "react-router-dom";

import { type ErrorMessageVariants } from "../../components/documentUploadModals/constants";
import { type RefetchDocumentPropertiesProps } from "../useDocumentProperties";
import { HelloSignErrorReason } from "./constants";

interface UseHelloSignHandleSuccessOrErrorProps {
  refetchDocumentProperties: (props: RefetchDocumentPropertiesProps) => Promise<void>;
  openSuccessModal: (isApproved: boolean) => void;
  openErrorModal: (errorMessageVariant?: ErrorMessageVariants) => void;
  requirementId?: string;
  helloSignSessionId?: string;
  onFinish?: () => void;
  onUnfinishedState?: () => void;
}

export function useHelloSignHandleSuccessOrError(props: UseHelloSignHandleSuccessOrErrorProps) {
  const {
    refetchDocumentProperties,
    openSuccessModal,
    openErrorModal,
    requirementId,
    helloSignSessionId,
    onFinish,
    onUnfinishedState,
  } = props;

  const history = useHistory();

  const updateRequirementStatusOnPage = useCallback(
    (documentStatus: DocumentStatus) => {
      const params = new URLSearchParams(window.location.search);
      params.set(
        "requirementStatus",
        documentStatus === DocumentStatus.APPROVED
          ? DocumentRequirementType.COMPLETED
          : DocumentRequirementType.PENDING
      );
      history.replace({ search: params.toString() });
      void refetchDocumentProperties({ includeRejectedDocumentNotification: false });
    },
    [history, refetchDocumentProperties]
  );

  const handleHelloSignError = useCallback(
    (errorReason?: HelloSignErrorReason) => {
      onFinish?.();
      openErrorModal();
      logEvent(APP_V2_APP_EVENTS.HELLO_SIGN_ERROR_EVENT, {
        errorReason,
        requirementId,
        helloSignSessionId,
      });
    },
    [helloSignSessionId, onFinish, openErrorModal, requirementId]
  );

  const handleHelloSignSuccess = useCallback(
    (helloSignSession: HelloSignSessionResponse) => {
      if (helloSignSession.data.attributes.status === HelloSignWorkflowSessionStatus.ERRORED) {
        handleHelloSignError(HelloSignErrorReason.HELLOSIGN_ERROR);
        return;
      }

      if (
        [HelloSignWorkflowSessionStatus.SIGNED, HelloSignWorkflowSessionStatus.COMPLETED].includes(
          helloSignSession.data.attributes.status
        )
      ) {
        const documentId = helloSignSession.data.relationships.document?.data.id;
        const document = helloSignSession.included?.find((included) => included.id === documentId);

        if (isNullOrUndefined(document)) {
          handleHelloSignError(HelloSignErrorReason.MISSING_DOCUMENT);
          return;
        }

        const documentStatus = document.attributes.status;
        onFinish?.();
        openSuccessModal(documentStatus === DocumentStatus.APPROVED);
        updateRequirementStatusOnPage(documentStatus);
        return;
      }

      onUnfinishedState?.();
    },
    [
      handleHelloSignError,
      openSuccessModal,
      updateRequirementStatusOnPage,
      onFinish,
      onUnfinishedState,
    ]
  );

  return { handleHelloSignSuccess, handleHelloSignError };
}
