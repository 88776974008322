import { useModalState } from "@clipboard-health/ui-react";
import { useState } from "react";

import {
  DocumentUploadCompletedModalButtonText,
  ErrorMessageVariants,
} from "../components/documentUploadModals/constants";
import { type DocumentUploadErrorModalProps } from "../components/documentUploadModals/DocumentUploadErrorModal";
import { type DocumentUploadCompletedModalProps } from "../components/documentUploadModals/types";

export type SetDocumentCompletedModalProps = Omit<DocumentUploadCompletedModalProps, "modalState">;
export type SetDocumentUploadErrorModalProps = Omit<DocumentUploadErrorModalProps, "modalState">;

export function useDocumentLoadingModals() {
  const documentUploadingModalState = useModalState();
  const documentCompletedModalState = useModalState();
  const documentErroredModalState = useModalState();

  const [documentCompletedModalProps, setDocumentCompletedModalProps] =
    useState<SetDocumentCompletedModalProps>({
      isApproved: false,
      viewDocumentButtonText: DocumentUploadCompletedModalButtonText.VIEW_DOCUMENT,
    });

  const [documentUploadErrorModalProps, setDocumentUploadErrorModalProps] =
    useState<SetDocumentUploadErrorModalProps>({
      signAgain: false,
      errorMessage: ErrorMessageVariants.UNEXPECTED_ERROR,
    });

  return {
    documentUploadingModalState,
    documentCompletedModalState,
    documentErroredModalState,
    documentCompletedModalProps,
    setDocumentCompletedModalProps,
    documentUploadErrorModalProps,
    setDocumentUploadErrorModalProps,
  };
}
