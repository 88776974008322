import { type UseModalState } from "@clipboard-health/ui-react";
import { type Dispatch, type SetStateAction, useCallback } from "react";

import {
  DocumentUploadCompletedModalButtonText,
  ErrorMessageVariants,
} from "../../components/documentUploadModals/constants";
import {
  type SetDocumentCompletedModalProps,
  type SetDocumentUploadErrorModalProps,
} from "../useDocumentLoadingModals";

interface UseHelloSignModalsProps {
  setDocumentCompletedModalProps: Dispatch<SetStateAction<SetDocumentCompletedModalProps>>;
  setDocumentUploadErrorModalProps: Dispatch<SetStateAction<SetDocumentUploadErrorModalProps>>;
  documentErroredModalState: UseModalState;
  documentCompletedModalState: UseModalState;
}

export function useHelloSignModals(props: UseHelloSignModalsProps) {
  const {
    setDocumentCompletedModalProps,
    setDocumentUploadErrorModalProps,
    documentErroredModalState,
    documentCompletedModalState,
  } = props;

  const { openModal: openDocumentCompletedModal, closeModal: closeDocumentCompletedModal } =
    documentCompletedModalState;

  const openErrorModal = useCallback(
    (errorMessageVariant?: ErrorMessageVariants, signAgain?: boolean) => {
      setDocumentUploadErrorModalProps({
        signAgain: signAgain ?? true,
        errorMessage: errorMessageVariant ?? ErrorMessageVariants.UNEXPECTED_ERROR,
      });
      documentErroredModalState.openModal();
    },
    [setDocumentUploadErrorModalProps, documentErroredModalState]
  );

  const openSuccessModal = useCallback(
    (isApproved: boolean) => {
      setDocumentCompletedModalProps({
        isApproved,
        viewDocumentButtonText: DocumentUploadCompletedModalButtonText.OKAY,
        onButtonCtaClick: closeDocumentCompletedModal,
      });
      openDocumentCompletedModal();
    },
    [setDocumentCompletedModalProps, openDocumentCompletedModal, closeDocumentCompletedModal]
  );

  return { openErrorModal, openSuccessModal };
}
