import { isDefined } from "@clipboard-health/util-ts";
import { Resources } from "@src/appV2/Accounts/Documents/resources/constants";
import { useDefinedWorker } from "@src/appV2/Worker/useDefinedWorker";
import { useCallback, useEffect, useRef, useState } from "react";

import {
  HELLOSIGN_POLLING_INTERVAL_MS,
  HELLOSIGN_POLLING_TIMEOUT_MS,
} from "../../api/hellosign/constants";
import { useGetHelloSignSession } from "../../api/hellosign/useGetHelloSignSession";
import { type ErrorMessageVariants } from "../../components/documentUploadModals/constants";
import { type RefetchDocumentPropertiesProps } from "../useDocumentProperties";
import { HelloSignErrorReason } from "./constants";
import { useHelloSignHandleSuccessOrError } from "./useHelloSignHandleSuccessOrError";

interface UseHelloSignPollingProps {
  requirementId?: string;
  helloSignSessionId?: string;
  openErrorModal: (errorMessageVariant?: ErrorMessageVariants) => void;
  openSuccessModal: (isApproved: boolean) => void;
  refetchDocumentProperties: (props: RefetchDocumentPropertiesProps) => Promise<void>;
}

export function useHelloSignPolling(props: UseHelloSignPollingProps) {
  const {
    requirementId,
    helloSignSessionId,
    openErrorModal,
    openSuccessModal,
    refetchDocumentProperties,
  } = props;
  const [isPollingHelloSignSession, setIsPollingHelloSignSession] = useState<boolean>(false);
  const worker = useDefinedWorker();

  const {
    data: helloSignSession,
    isSuccess,
    isError,
  } = useGetHelloSignSession(
    {
      requirementId: requirementId ?? "",
      helloSignSessionId: helloSignSessionId ?? "",
      workerId: worker.userId,
      include: Resources.HCP_DOCUMENTS,
    },
    {
      enabled:
        isDefined(requirementId) && isDefined(helloSignSessionId) && isPollingHelloSignSession,
      refetchInterval: HELLOSIGN_POLLING_INTERVAL_MS,
      retry: false,
    }
  );

  // Its necessary to define the type as NodeJS.Timeout | null here
  // eslint-disable-next-line @typescript-eslint/ban-types
  const timeoutRef = useRef<NodeJS.Timeout | null>(null);

  const clearTimeoutRef = useCallback(() => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
      timeoutRef.current = null;
    }
  }, []);

  const stopPollingAndClearTimeout = useCallback(() => {
    setIsPollingHelloSignSession(false);
    clearTimeoutRef();
  }, [clearTimeoutRef]);

  const { handleHelloSignError, handleHelloSignSuccess } = useHelloSignHandleSuccessOrError({
    refetchDocumentProperties,
    openSuccessModal,
    openErrorModal,
    requirementId,
    helloSignSessionId,
    onFinish: stopPollingAndClearTimeout,
  });

  useEffect(() => {
    if (!isPollingHelloSignSession) {
      return undefined;
    }

    timeoutRef.current = setTimeout(() => {
      handleHelloSignError(HelloSignErrorReason.TIMEOUT);
    }, HELLOSIGN_POLLING_TIMEOUT_MS);

    if (isError) {
      handleHelloSignError(HelloSignErrorReason.BACKEND_ERROR);
    }

    if (isSuccess) {
      handleHelloSignSuccess(helloSignSession);
    }

    return clearTimeoutRef;
  }, [
    clearTimeoutRef,
    handleHelloSignError,
    handleHelloSignSuccess,
    helloSignSession,
    isError,
    isPollingHelloSignSession,
    isSuccess,
  ]);

  return {
    isPollingHelloSignSession,
    setIsPollingHelloSignSession,
  };
}
